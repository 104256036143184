import React from 'react';

export default function DashboardIcon() {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
       <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="60%" stopColor="#EEAB36" />
        <stop offset="100%" stopColor="#F9D282" />
      </linearGradient>
      </defs>
      <path
        d="M12 2.954a10 10 0 0 1 6.222 17.829a1 1 0 0 1 -.622 .217h-11.2a1 1 0 0 1 -.622 -.217a10 10 0 0 1 6.222 -17.829m4.207 5.839a1 1 0 0 0 -1.414 0l-2.276 2.274a2.003 2.003 0 0 0 -2.514 1.815l-.003 .118a2 2 0 1 0 3.933 -.517l2.274 -2.276a1 1 0 0 0 0 -1.414"
        fill="url(#iconGradient)"
      />
    </svg>
  );
}

